import React from "react"
import SEO from "../components/common/SEO/SEO"
import AboutContainer from "../components/about/AboutContainer/AboutContainer"

export default () => (
  <div className="application">
    <SEO />
    <AboutContainer />
  </div>
)
