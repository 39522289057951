import React from "react"
import { useStaticQuery, graphql, navigate, Link } from "gatsby"
import Img from "gatsby-image"
import ContentLayout from "../../common/ContentLayout/ContentLayout"
import Text from "../../common/Text/Text"
import Button from "../../common/Button/Button"
import SocialMediaFooter from "../../common/SocialMediaFooter/SocialMediaFooter"
import Header from "../../common/Header/Header"
import styles from "./AboutContainer.module.css"

const aboutText1 =
  "Our roots are in the North, in the cold but oh so beautiful Helsinki. Throw a stone anywhere in Helsinki, and chances are that it will fall on a stunning building. We are standing firmly on top of the Scandinavian design tradition, which is historically synonymous with good design."
const aboutText2 =
  "Today, we have expanded to Asia, where the design industry is changing and growing fast. We operate both in Europe and Asia, building the design bridge between the East and West, with plans to venture further to the USA."
const aboutText3 =
  "In 2015, we noticed the demand for design was growing. But most companies are struggling to find the right kind of design talent to their jobs and projects in an easy way. The traditional recruiting and headhunting don’t have the right expertise to manage this growth. We formed our multifaceted team with deep knowledge of the design field and started to create a solution to solve the problem. Now we can provide a unique visual matching platform, for designpeople by designpeople."

const AboutContainer = () => {
  const data = useStaticQuery(graphql`
    query {
      about1: file(relativePath: { eq: "about1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about2: file(relativePath: { eq: "about2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <Header />
      <ContentLayout title="About Creagent">
        <Text content={aboutText1} paragraphBreak="big" />
        <Img
          fluid={data.about1.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
        <Text content={aboutText2} paragraphBreak="big" />
        <Img
          fluid={data.about2.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
        <Text content={aboutText3} paragraphBreak="big" />
        <div className={styles.buttonContainer}>
          <Button
            text="Find designers now"
            onClick={e => {
              e.preventDefault()
              navigate("/client_form")
            }}
            style={{
              width: "200px",
            }}
          />
          <Link to="/#why-creagent-is-different" className={styles.link}>
            or learn more
          </Link>
        </div>
        <SocialMediaFooter />
      </ContentLayout>
    </div>
  )
}
export default AboutContainer
